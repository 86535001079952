.section.section-contact {
  position: relative;
  background: url('img/stars.jpg');
  color: white;
  text-align: center;

  .fa {
    display: block;
    font-size: 3em;
    margin-bottom: 10px;
  }

  .item {
    margin-top: 40px;
  }

  a {
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
}