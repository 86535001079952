/**
 * Sidebar
 */

@sidebar-width: 220px;
@sidebar-bg:    @brand-fourth;


.wrapper {
  box-shadow: 10px 0 10px fade(black,20%);
  .transition(margin .3s);
}
.js-wrapper-aside {
  margin-left: -@sidebar-width;
  margin-right: @sidebar-width;
}
.navbar {
  .transition(all .3s);
}
.js-navbar-aside {
  left: -@sidebar-width;
  right: @sidebar-width;
}

/**
 * Sidebar general
 */

.sidebar {
  position: fixed;
  top: 0; right: -@sidebar-width;
  width: @sidebar-width;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  #gradient>.vertical(darken(@sidebar-bg,2%);@sidebar-bg);
  .transition(all .3s);
}
.js-sidebar-aside {
  right: 0;
}
.sidebar__wrapper {
  position: relative;
  min-height: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 90px;
}
.sidebar__close {
  position: absolute;
  top: 0; right: 0;
  width: 40px;
  padding: 10px;
  text-align: center;
  line-height: 20px;
  color: fade(white,60%);
  cursor: pointer;
}
.sidebar__heading {
  margin: 30px 25px 20px;
  font-size: @font-size-base;
  font-weight: 700;
  color: @brand-second;
  text-transform: uppercase;
  .text-overflow();
}


/**
 * Sidebar form
 */

.sidebar__form {
  padding: 30px 25px 20px;
}

/**
 * Sidebar menu
 */

.sidebar__menu {
  list-style: none;
  padding: 0;
  margin: 0;

  > li {

    > a {
      display: block;
      padding: 4px 25px;
      font-weight: 600;
      color: fade(white,70%);
      text-decoration: none;
      .text-overflow();
      .transition(color .15s);

      &:hover, &:focus {
        color: white;
      }
    }
  }
}


/**
 * Sidebar logo
 */

.sidebar__logo {
  position: absolute;
  bottom: 0; left: 0; right: 0;
  padding: 20px 25px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: fade(white,10%);
  .text-overflow();
}