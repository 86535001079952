.section.section-ideas {
  .features__item {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .features-item__icon {
    text-align: center;
    font-size: 26px;
  }
  .features-item__body {
    overflow: hidden;
  }
  .features-item-body__title {
    margin-bottom: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .features-item-body__summary {
    color: @gray-light;

    a {
      color: @gray-light;
    }
  }

  .ideas-icon {
    width: 100%;
    height: 100px;
    background-position: center;
    background-size: contain;
    display: block;
    background-repeat: no-repeat;
  }
  .ideas-icon--gameshow {
    background-image: url("img/ideas_gameshow.png");
  }
  .ideas-icon--lotteries {
    background-image: url("img/ideas_lotteries.png");
  }
  .ideas-icon--scratch {
    background-image: url("img/ideas_scratch.png");
  }
  .ideas-icon--slots {
    background-image: url("img/ideas_slots.png");
  }
}