.section.section-results {
  font-size: 1.2em;
  border-bottom: none;
  .icon {
    position: relative;
    font-size: 6em;
    overflow: hidden;
    i {
      position: relative;
      top: -30px;
    }
    ul.lotto-balls {
      li {
        position: absolute;
        font-size: 0.2em;
      }
    }
  }

  ul.benefits {
    margin-top: 0px;
  }

  h1 {
    margin-top: 60px;
    margin-bottom: 60px;
    color: @gray-base;
  }
}

.section.section-benefits {
  padding-bottom:0px;
  margin-top: 20px;

  .item {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .icon {
    text-align: center;
    font-size: 26px;
  }
  .text {
    overflow: hidden;
    margin-bottom: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  position: relative;
  text-align: center;

  &:before, &:after {
    position: absolute;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    content: "";
  }
  &:before {
    @arrow-height: 15px;
    @arrow-width:  25px;
    top: -@arrow-height*2;
    margin-left: -@arrow-width;
    border-width: @arrow-height @arrow-width;
    border-color: transparent transparent #eee transparent;
  }
  &:after {
    @arrow-height: 13px;
    @arrow-width:  23px;
    top: -@arrow-height*2;
    margin-left: -@arrow-width;
    border-width: @arrow-height @arrow-width;
    border-color: transparent transparent #f5f5f5 transparent;
  }
}