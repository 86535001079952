.navbar-brand {
    background-image: url("img/gamevy.png");
    background-repeat: no-repeat;
    margin-left: 15px;
    background-position: 0px 50%;
    background-size: 100%;
    width: 89px;
}
.navbar-default .navbar-brand {
    background-image: url("img/gamevy-white.png");
}