.section.section-testimonials {
  .review__icon {
    margin-top: 80px;
    text-align: center;
    font-size: 28px;
    color: @gray-light;
  }
  .review__icon + .heading {
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .review__item {
    margin-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .review-item__quot {
    position: relative;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: @border-radius-base;
    background: #f5f5f5;
    color: @gray-light;

    &:after {
      @arrow-height: 10px;
      @arrow-width:  10px;
      position: absolute;
      display: block;
      bottom: -@arrow-height*2; left: 40px;
      width: 0;
      height: 0;
      border-width: @arrow-height @arrow-width;
      border-color: #f5f5f5 transparent transparent transparent;
      border-style: solid;
      content: "";
    }

    &.video {
      padding: 0;

      &:after {
        border-color: #000000 transparent transparent transparent;
      }
    }
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }


  .ice-review .review-item__quot {
    @media (min-width: @screen-md-min) {
      background-image: url('img/ice_award.png');
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: 100% 0px;
      padding-right: 50%;
      min-height: 100px;
    }
  }

  .review-item-about__avatar {
    float: left;
    width: 70px;
    height: 70px;
    margin: 0 20px 0 10px;
    border-radius: 50%;
    border: 1px solid #eee;
    overflow: hidden;
  }
  .review-item-about__content {
    overflow: hidden;
    padding-top: 15px;
  }
  .review-item-about__name {
    font-weight: 700;
  }
  .review-item-about__position {
    color: @gray-light;
  }
  #review__carousel {
    padding-bottom: 60px;
  }
  #review__carousel .owl-controls {
    margin-top: 20px;
    text-align: center;
  }
  #review__carousel .owl-controls .owl-buttons div {
    @size: 30px;
    display: inline-block;
    width: @size;
    height: @size;
    line-height: @size;
    margin: 0 5px;
    background: white;
    border: 1px solid #eee;
    border-radius: @border-radius-small;
    font-weight: 700;
    color: @gray-light;
    .transition(all .15s);

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .press-more {
    .press-link a {
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 70px;
      width: 100%;
      line-height: 70px;
      margin: 10px;
    }
  }
}