/*
 * Typography
 */

@font-family-sans-serif:      'Open Sans', sans-serif;
@headings-font-family:        'Karla', sans-serif;

@font-size-base:              13px;
@line-height-base:            1.8;


a {
  .transition(all .2s);
}