.section.section-devices {
  position: relative;

  background-color: black;

  color: white;
  padding-bottom: 0px;

  .col-sm-12 {
    padding: 0;
  }

  border-bottom: none;
}
