.container.game {
    .game-languages-wide {
        ul {
            li {
                height: 70px;
                line-height: 70px;
                list-style-type: none;
                display: inline-block;
                padding-left: 10px;
                padding-right: 10px;
            }

            li.disabled {
                a {
                    color: #000;
                    cursor: default;
                    text-decoration: none;
                }
            }
        }
    }

    .game-languages-narrow {
        .dropdown {
            margin-bottom: 10px;
        }
    }

    .game-iframe {
        width: 100%;
        border: none;
    }
}
