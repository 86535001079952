/**
 * Utilities
 */

.block(@size) {
  width: @size;
  height: @size;
  line-height: @size;
  text-align: center;
}


// Animation delays

.delay_1 {
  .animation-delay(1s);
}
.delay_2 {
  .animation-delay(2s);
}
.delay_3 {
  .animation-delay(3s);
}
.delay_4 {
  .animation-delay(4s);
}
.delay_5 {
  .animation-delay(5s);
}