.section-games {
  background-color: #000;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: #ddd;

  /* Set anchor a bit higher to make the scroll look nicer with the flag */
  #games {
    position: relative;
    top: -100px;
  }

  .item {
    margin-top: 40px;
    margin-bottom: 0px;

    .heading {
      color: @gray-light;
      margin-top: 20px;
      margin-bottom: 30px;
      text-align: left;
    }
  }
  .intro {
    .ice-2016-banner {
      position: relative;
      top: -10px;
      float: left;
      margin-right: 40px;
      margin-left: 64px;
    }

    h2 {
      text-align: left;
      font-size: 2.5em;
      margin-top: 60px;
      margin-bottom: 60px;
      color: #fff;
    }

    @media(max-width: @grid-float-breakpoint) {
      text-align: center;
      .ice-2016-banner {
        float: initial;
      }

      h2 {
        text-align: center;
      }
    }
  }

  .col-game {
    margin-bottom: 30px;
  }

  .col-game a {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;

    .game-logo {
      width: 100%;
      height: 100%;
      margin: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 1;
      transition: opacity 0.3s;
    }

    .hover-text {
      position: absolute;
      background: #ee004f;
      border-radius: 3px;
      padding: 5px;
      top: 50%;
      height: 20%;
      margin-top: -10%;
      left: 25%;
      width: 50%;
      text-align: center;
      color: #fff;
      opacity: 0;
      transition: opacity 0.3s;

      &.coming-soon {
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
      }
    }

    &:hover {
      .hover-text {
        opacity: 1;
      }

      .game-logo {
        opacity: 0.5;
      }
    }
  }

  /* Try to maintain aspect ratio */
  .game-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .game-wrapper:after {
    padding-top: 56.25%;
    /* 16:9 ratio */
    display: block;
    content: '';
  }


}
