.hero {
  position: relative;
  padding: 50px + @navbar-height 0 10px;
  background-position: center 10px;
  background-repeat: no-repeat;
  background-size: auto 450px;
  background-image: url(img/bg.jpg);
  background-color: black;
  overflow: hidden;
}

@media(min-width: @grid-float-breakpoint) {
  .hero {
    padding: 50px+@navbar-height 0 0px;
  }
}
.hero__heading {
  margin-top: 0;
  font-size: 30px;
  color: white;
  text-align: center;
}
.hero__heading + .hero-heading__sub {
  color: fade(white,90%);
  text-align: center;
  padding: 0 50px;
  margin-top: 230px;
  font-size: 1.3em;
  margin-bottom: 30px;
}

